import { useTranslate } from '@tolgee/react';
import { ALL, GOOGLE, PINTEREST } from '../../../../config/constants';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPostDataAttribute } from '../../../../redux/slices/lomavisCreator/lomavisCreator';
import { CreatorVerticalSeparator } from '../include/VerticalSeperator';
import { link } from 'fs';
import { id } from 'date-fns/locale';

export const PinterestAdditionalOptions: React.FC<any> = ({
  platform = PINTEREST
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [sectionsList, setSectionsList] = useState<any[]>([]);
  /*
    "title": null | string,
  "board": {
    "id": 1234,
    "name": 1234
  },
  "standard_post": true | false,
  "links": []
  }
  */
  const {
    lomavisCreatorConfig: {
      additional_data: { [platform]: platformConfig }
    },
    postData: {
      [PINTEREST]: { additional_publication_data = {}, media = [] }
    }
  } = useSelector((state: any) => state.lomavisCreator);

  useEffect(() => {
    // set the sections list
      if (platformConfig?.boards && platformConfig?.boards?.length > 0) {
        setSectionsList(
          platformConfig?.boards?.find(
            (board) => board?.id === additional_publication_data?.board?.id
          )?.sections || []
        );
      }
  }, [additional_publication_data?.board?.id]);

  return (
    <div className="border border-secondary px-2 py-2 rounded">
      <label htmlFor="google-type-select">
        {t('lomavisCreator.additionalOptions.select_board')}
      </label>
      <Form.Select
        id="google-type-select"
        aria-label="Default select example"
        size="sm"
        // Use "" (empty string) if there's no board selected yet:
        value={additional_publication_data?.board?.id || ''}
        onChange={(e) => {
          // set the sections list
          dispatch(
            setPostDataAttribute({
              platform: PINTEREST,
              attribute: 'additional_publication_data',
              value: {
                ...additional_publication_data,
                board: {
                  id: e.target.value || null,
                  name: e.target.selectedOptions[0].text
                }
              }
            })
          );
        }}
      >
        {/* Default unselectable option */}
        <option value=""></option>

        {platformConfig && platformConfig?.boards && platformConfig?.boards?.length > 0 ? platformConfig?.boards?.map((board) => (
            <option key={board.id} value={board.id}>
                {board.name}
            </option>
        )): ''}
      </Form.Select>
      <CreatorVerticalSeparator />
      <label htmlFor="section-select">
        {t('lomavisCreator.additionalOptions.select_section')}
      </label>
      <Form.Select
        id="section-select"
        aria-label="Default select example"
        size="sm"
        disabled={!additional_publication_data?.board?.id}
        value={additional_publication_data?.section?.id || ''}
        onChange={(e) => {
          dispatch(
            setPostDataAttribute({
              platform: PINTEREST,
              attribute: 'additional_publication_data',
              value: {
                ...additional_publication_data,
                section: {
                  id: e.target.value || null,
                  name: e.target.selectedOptions[0].text
                }
              }
            })
          );
        }}
      >
        {/* Default unselectable option */}
        <option value="">
          {!additional_publication_data?.board?.id ? (
            <span className="text-muted">
              {t('lomavisCreator.additionalOptions.select_board_first')}
            </span>
          ) : (
            ''
          )}
        </option>
        {sectionsList.map((section) => (
          <option key={section.id} value={section.id}>
            {section.name}
          </option>
        ))}
      </Form.Select>

      <CreatorVerticalSeparator />
      <div>
        <label htmlFor="title">
          {t('lomavisCreator.additionalOptions.title')}
        </label>
        <input
          id="title"
          type="text"
          value={additional_publication_data?.title || ''}
          className="form-control form-control-sm"
          onChange={(e) => {
            dispatch(
              setPostDataAttribute({
                platform: PINTEREST,
                attribute: 'additional_publication_data',
                value: {
                  ...additional_publication_data,
                  title: e.target.value
                }
              })
            );
          }}
        />
      </div>
      <div className="mt-4" />
      <div className="d-flex justify-content-between">
        <div>
          <p className="m-0">
            {t('lomavisCreator.additionalOptions.standard_post')}
          </p>
          <span className="text-muted">
            {t('lomavisCreator.additionalOptions.standard_post_helper')}
          </span>
        </div>
        <Form.Check
          type="switch"
          id="standard-post-switch"
          checked={additional_publication_data?.standard_post}
          onChange={(e) => {
            dispatch(
              setPostDataAttribute({
                platform: PINTEREST,
                attribute: 'additional_publication_data',
                value: {
                  ...additional_publication_data,
                  standard_post: e.target.checked
                }
              })
            );
          }}
        />
      </div>
      <CreatorVerticalSeparator />
      <div>
        <label htmlFor="link">
          {t('lomavisCreator.additionalOptions.link')}
        </label>
        <input
          type="text"
          className="form-control form-control-sm"
          value={additional_publication_data?.link}
          onChange={(e) => {
            dispatch(
              setPostDataAttribute({
                platform: PINTEREST,
                attribute: 'additional_publication_data',
                value: {
                  ...additional_publication_data,
                  link: e.target.value
                }
              })
            );
          }}
        />
        <span className="text-muted">
          {t('lomavisCreator.additionalOptions.pinterest_link_helper')}
        </span>
      </div>
      <CreatorVerticalSeparator />
      {media?.filter((img) => img.media_type == 'image').length > 0 && (
        <div className="mt-2">
          <span className="fw-bold">
            {t('lomavisCreator.additionalOptions.media_links')}
          </span>
          <br />
          <span className="text-muted">
            {t('lomavisCreator.additionalOptions.media_links_helper')}
          </span>
          <CreatorVerticalSeparator />
          {media
            ?.filter((img) => img.media_type == 'image')
            ?.map((img, index) => (
              <div key={img.uuid} className="mb-2">
                <p className="m-0 me-2">
                  {`${t('lomavisCreator.additionalOptions.link')} ${index + 1}`}
                </p>
                <input
                  type="text"
                  value={
                    additional_publication_data?.links?.find(
                      (link, i) => link.image === img.uuid
                    )?.link
                  }
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    const links = [
                      ...(additional_publication_data?.links || [])
                    ];
                    const link = links.find(
                      (link, i) => link.image === img.uuid
                    );
                    if (link) {
                      const newLink = { ...link };
                      newLink.link = e.target.value;
                      // update the links array
                      links.splice(
                        links.findIndex((link, i) => link.image === img.uuid),
                        1,
                        newLink
                      );
                    } else {
                      links.push({
                        image: img.uuid,
                        link: e.target.value
                      });
                    }
                    dispatch(
                      setPostDataAttribute({
                        platform: PINTEREST,
                        attribute: 'additional_publication_data',
                        value: {
                          ...additional_publication_data,
                          links
                        }
                      })
                    );
                  }}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
